import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BuildIcon from "@material-ui/icons/Build";
import EmailIcon from "@material-ui/icons/Email";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import StorefrontIcon from "@material-ui/icons/Storefront";
import WorkIcon from "@material-ui/icons/Work";
import styled from "styled-components";
import LayoutTooltip from "../components/LayoutTooltip";
import { blueGrey } from "@material-ui/core/colors";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import { LocalShipping } from "@material-ui/icons";

const colorIcon = {
  color: blueGrey[900],
};
const LetterIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
  color: #637381;
  font-size: 1.5rem;
  font-weight: 500;
`;

export const categoriesAdmin = [
  {
    text: "Ventas",
    icon: (
      <LayoutTooltip title="Remodelaciones">
        <LoyaltyIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/admin/ventas",
  },
  {
    text: "Remodelaciones",
    icon: (
      <LayoutTooltip title="Remodelaciones">
        <PeopleAltIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/admin/remodelaciones",
  },
  // TODO: in the future implement presential consultancy management
  // {
  //   text: "A.Presenciales",
  //   icon: (
  //     <LayoutTooltip title="Asesorías presenciales">
  //       <PeopleAltIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/admin/asesorías-presenciales",
  // },
  {
    text: "A.Virtuales",
    icon: (
      <LayoutTooltip title="Mis asesorías virtuales">
        <RecordVoiceOverIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/admin/asesorías-virtuales",
  },
  // TODO: In the future implement category creation section with selection fields for each new category to be created
  // {
  //   text: "Categorias",
  //   icon: (
  //     <LayoutTooltip title="Categorias">
  //       <AppsIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/admin/categorias",
  // },
  {
    text: "Clientes",

    icon: (
      <LayoutTooltip title="Clientes">
        <LetterIcon>C</LetterIcon>
      </LayoutTooltip>
    ),
    link: "/admin/clientes",
  },
  {
    text: "Diseñadores",

    icon: (
      <LayoutTooltip title="Diseñadores">
        <LetterIcon>D</LetterIcon>
      </LayoutTooltip>
    ),
    link: "/admin/disenadores",
  },
  {
    text: "Proveedores",
    icon: (
      <LayoutTooltip title="Proveedores">
        <LetterIcon>P</LetterIcon>
      </LayoutTooltip>
    ),
    link: "/admin/proveedores",
  },
  {
    text: "Productos",
    icon: (
      <LayoutTooltip title="Productos">
        <StorefrontIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/admin/lista-productos",
  },
  {
    text: "Mensajería",
    icon: (
      <LayoutTooltip title="Mensajería">
        <EmailIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/admin/mensajeria",
  },
];

export const categoriesSupplier = [
  {
    text: "Ventas",
    icon: <LoyaltyIcon style={colorIcon} />,
    link: "/proveedor/ventas",
  },
  {
    text: "Envíos",
    icon: <LocalShipping style={colorIcon} />,
    link: "/proveedor/envios",
  },
  // TODO: Remove this when we have support for local shipping
  // {
  //   text: "Clientes",
  //   icon: <PeopleIcon style={colorIcon} />,
  //   link: "/proveedor/dashboard",
  // },
  {
    text: "Productos",
    icon: <LetterIcon>P</LetterIcon>,
    // link: "/proveedor/lista-productos",
    subItems: [
      {
        text: "Lista de productos",
        link: "/proveedor/producto/lista",
      },
      {
        text: "Crear producto",
        link: "/proveedor/producto/nuevo",
      },
    ],
  },
  {
    text: "Asesores",
    icon: <PeopleAltIcon style={colorIcon} />,
    subItems: [
      {
        text: "Lista de asesores",
        link: "/proveedor/asesor/lista",
      },
      {
        text: "Crear asesor",
        link: "/proveedor/asesor/nuevo",
      },
    ],
  },
  // TODO:  remove this while applying statistics
  // {
  //   text: "Estadísticas",
  //   icon: <AssessmentIcon style={colorIcon} />,
  //   link: "/proveedor/estadisticas",
  // },
  {
    text: "E-Commerce",
    icon: <SwapHorizIcon style={colorIcon} />,
    link: "/proveedor/preferencias",
  },
  {
    text: "Configuración",
    icon: <BuildIcon style={colorIcon} />,
    link: "/proveedor/configuracion",
  },
];

export const categoriesDesigner = [
  {
    text: "Mis compras",
    icon: (
      <LayoutTooltip title="Mis compras">
        <LocalMallIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/mis-compras/lista",
  },
  {
    text: "Ver productos",
    icon: (
      <LayoutTooltip title="Ver tienda">
        <ShoppingCart style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/productos",
  },
  {
    text: "Mensajería",
    icon: (
      <LayoutTooltip title="Mensajería">
        <EmailIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/disenador/mensajeria",
  },
  // TODO: in the future implement presential consultancy management
  // {
  //   text: "A.Presenciales",
  //   icon: (
  //     <LayoutTooltip title="A.Presenciales">
  //       <PeopleAltIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/disenador/dashboard",
  // },
  // TODO: in the future implement virtual consultancy management
  // {
  //   text: "A.Virtuales",
  //   icon: (
  //     <LayoutTooltip title="A.Virtuales">
  //       <RecordVoiceOverIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/disenador/mis-asesorias-virtuales/",
  // },
  // TODO: in the future implement presential consultancy management
  // {
  //   text: "Productos",
  //   icon: (
  //     <LayoutTooltip title="Productos">
  //       <StorefrontIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/disenador/productos",
  // },
  // {
  //   text: "Estadísticas",
  //   icon: (
  //     <LayoutTooltip title="Estadísticas">
  //       <AssessmentIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/disenador/estadisticas",
  // },
  {
    text: "Configuración",
    icon: (
      <LayoutTooltip title="Configuración">
        <BuildIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/disenador/configuracion",
  },
];

export const categoriesLSH = [
  {
    text: "Ordenes pagadas",
    icon: (
      <LayoutTooltip title="Ordenes pagadas">
        <WorkIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/lsh/dashboard",
  },
  {
    text: "Productos por agregar precio de instalación",
    icon: (
      <LayoutTooltip title="Productos por agregar precio de instalación">
        <LocalOfferIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/lsh/revision-productos",
  },
  {
    text: "Productos",
    icon: (
      <LayoutTooltip title="Productos">
        <StorefrontIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/lsh/lista-productos",
  },
];

export const categoriesClient = [
  //TODO: This will be removed while defining whether it targets the current ecommerce or is removed completely
  // {
  //   text: "Ver productos",
  //   icon: (
  //     <LayoutTooltip title="Ver tienda">
  //       <ShoppingCart style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/productos",
  // },
  {
    text: "Mi cuenta",
    icon: (
      <LayoutTooltip title="Mi cuenta">
        <AccountCircleIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/cliente/configuracion",
  },
  // TODO: in the future implement presential consultancy management
  // {
  //   text: "A.Presenciales",
  //   icon: (
  //     <LayoutTooltip title="A.Presenciales">
  //       <PeopleAltIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/cliente/asesorias-presenciales",
  // },
  {
    text: "Mis compras",
    icon: (
      <LayoutTooltip title="Mis compras">
        <LocalMallIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    link: "/mis-compras/lista",
  },
  // TODO: in the future implement virtual consultancy management
  // {
  //   text: "A.virtuales",
  //   icon: (
  //     <LayoutTooltip title="A.virtuales">
  //       <RecordVoiceOverIcon style={colorIcon} />
  //     </LayoutTooltip>
  //   ),
  //   link: "/cliente/asesorias",
  // },
  // TODO: it is provided until the component information is obtained
  // {
  //   text: "Mis compras",
  //   icon: <ShoppingCartIcon className={classes.icons} />,
  //   link: "/cliente/dashboard",
  // },
  // {
  //   text: "Ayuda/PQRS",
  //   icon: <HeadsetMicIcon className={classes.icons} />,
  //   link: "/cliente/pqrs",
  // },
  {
    text: "Asesorias",
    icon: (
      <LayoutTooltip title="Asesorias">
        <PeopleAltIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    subItems: [
      {
        text: "Activas",
        link: "/cliente/asesorias-activas/lista",
      },
      {
        text: "Completadas",
        link: "/cliente/asesorias-completadas/lista",
      },
    ],
  },
];

export const categoriesSalesperson = [
  {
    text: "Asesorias",
    icon: (
      <LayoutTooltip title="Asesorias">
        <PeopleAltIcon style={colorIcon} />
      </LayoutTooltip>
    ),
    subItems: [
      {
        text: "Activas",
        link: "/vendedor/asesorias-activas/lista",
      },
      {
        text: "Completadas",
        link: "/vendedor/asesorias-completadas/lista",
      },
    ],
  },
];
