import React from "react";
import styles from "./EcommerceChat.module.scss";
import EmptyChatImage from "../../assets/svg/ic-chat-active.svg";

type ChatEmptyStateProps = {
  currentRoleId: number;
};
const ChatEmptyState = ({ currentRoleId }: ChatEmptyStateProps) => {
  const isClient = currentRoleId === 1;
  return (
    <div className={styles.emptyChat}>
      <img
        src={EmptyChatImage}
        alt="No messages"
        className={styles.emptyChatImage}
      />

      {isClient ? (
        <div className={styles.emptyChatText}>
          <h6>Hola!</h6>
          <p>Escribe tu mensaje y un asesor te responderá pronto.</p>
        </div>
      ) : (
        <div className={styles.emptyChatText}>
          <h6>Hola!</h6>
          <p>
            El cliente ha abierto el chat, pero aún no ha enviado un mensaje.
          </p>
        </div>
      )}
    </div>
  );
};

export default ChatEmptyState;
