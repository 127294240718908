import { CardMedia, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Link from "@material-ui/core/Link";
import React from "react";
import { useAuth } from "../../../../services/auth";

type SalesConsultanciesCardProps = {
  consultancy: SalesConsultancy;
};

const SalesConsultanciesCard = ({
  consultancy,
}: SalesConsultanciesCardProps) => {
  const { currentRoleId } = useAuth();
  const { client, salesperson, product } = consultancy;
  const personName =
    currentRoleId !== 1
      ? `${client.first_name} ${client.last_name}`
      : `${salesperson.first_name} ${salesperson.last_name}`;
  const roleLabel = currentRoleId !== 1 ? "Cliente" : "Asesor";
  const rolePath = currentRoleId !== 1 ? "vendedor" : "cliente";

  const renderImg = (
    <CardMedia
      component="img"
      alt={product.name}
      image={product.image}
      title={product.name}
      style={{
        height: "100%",
        width: "100%",
        objectFit: "cover",
        position: "absolute",
        top: 0,
      }}
    />
  );
  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>{renderImg}</Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 3,
        }}
      >
        <Link
          className="subtitle-1"
          underline="hover"
          color="inherit"
          noWrap
          href={`/${rolePath}/asesoria/${consultancy.hashid}/chat`}
        >
          {product.name}
        </Link>

        <Typography component="span" variant="body1">
          <span className="subtitle-2">{roleLabel}:</span> {personName}
        </Typography>
      </Box>
    </Card>
  );
};

export default SalesConsultanciesCard;
