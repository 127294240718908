import Box from "@material-ui/core/Box";
import { useGetSalesConsultancies } from "../../../utils/react-query/querySalesConsultancy/queries";
import SalesConsultanciesList from "./SalesConsultanciesList/SalesConsultanciesList";

type SalesConsultanciesProps = {
  status: boolean;
};
const SalesConsultancies = ({ status }: SalesConsultanciesProps) => {
  const { data: salesConsultancies, isLoading: isLoadingSalesConsultancies } =
    useGetSalesConsultancies(status);

  if (isLoadingSalesConsultancies) {
    return <div>Cargando asesorías...</div>;
  }

  return (
    <section className="layout-dashboard-container">
      <div>
        <h4 className="header-title">
          Asesorias {!status ? "activas" : "completadas"}
        </h4>
        {/* TODO: In future implements dinamic BreadcrumbNav
        Ticket: https://team-1587508332888.atlassian.net/browse/FLIP-3104
        <BreadcrumbNav
          firstText="Vendedor"
          secondText="asesorias"
          secondLink="/proveedor/asesor/lista"
          thirdText={"lista"}
        /> */}
      </div>
      <SalesConsultanciesList salesConsultancies={salesConsultancies} />
      <Box sx={{ display: "flex", justifyContent: "center" }} mt={8} mb={2}>
        {/* TODO: In future implements Pagination
        Ticket: https://team-1587508332888.atlassian.net/browse/FLIP-3105
        <Pagination count={10} color="primary" /> */}
      </Box>
    </section>
  );
};

export default SalesConsultancies;
