import { CircleDot, Clock, EyeOff } from "lucide-react";
import React from "react";
import styles from "./ProfileMenu.module.scss";

const statuses: Status[] = [
  {
    id: "online",
    label: "Activo",
    icon: <CircleDot size={18} />,
    color: "online",
  },
  {
    id: "away",
    label: "Ocupado",
    icon: <Clock size={18} />,
    color: "away",
  },

  {
    id: "offline",
    label: "Desconectado",
    icon: <EyeOff size={18} />,
    color: "offline",
  },
];

type StatusOptionsMenuProps = {
  handleStatusChange: (status: string) => void;
};

const StatusOptionsMenu = ({ handleStatusChange }: StatusOptionsMenuProps) => {
  return (
    <div className={styles.statusMenuContentInfo}>
      {statuses.map((status) => (
        <button
          key={status.id}
          onClick={() => handleStatusChange(status.id as string)}
        >
          <span className={styles[status.color]}>{status.icon}</span>
          <span>{status.label}</span>
        </button>
      ))}
    </div>
  );
};

export default StatusOptionsMenu;
