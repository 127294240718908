import { formatChatDate } from "../../utils/formatChatDate";
import styles from "./EcommerceChat.module.scss";
type ChatMessageProps = {
  isCurrentUser: boolean;
  message: ChatMessages;
};

const ChatMessage = ({ isCurrentUser, message }: ChatMessageProps) => {
  let { message: text, date } = message;
  if (text.includes("https://")) {
    text = text.substring(0, 150) + " ...";
    return (
      <div>
        <a
          href={text}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          {text}
        </a>
      </div>
    );
  }

  return (
    <div
      key={message._id}
      className={`${styles.message} ${
        !isCurrentUser ? styles.justifyStart : styles.justifyEnd
      }`}
    >
      <div
        className={`${styles.messageInfo} ${
          !isCurrentUser ? styles.backgroundOther : styles.backgroundUser
        }`}
      >
        <p>{text}</p>
        <span>{formatChatDate(date)}</span>
      </div>
    </div>
  );
};

export default ChatMessage;
