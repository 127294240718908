import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import { BASE_API_URL } from "../../services/baseURL";

import "./Chat.css";

// SocketIO
import { io } from "socket.io-client";
import styled from "styled-components";
import { successToast } from "../../services/toasts";
import ChatMessageList from "./ChatMessageList";

const socket = io.connect(BASE_API_URL);

/**
 * EcommerceChat.
 */

function Chat({
  role,
  clientChatId,
  professionalChatId,
  roomId,
  handleShowChat,
  showChat,
  purchase,
}) {
  const [chatMessages, setChatMessages] = useState([]);
  const [messageToSend, setMessageToSend] = useState("");
  const [isKnockKnockBlocked, setIsKnockKnockBlocked] = useState(false);
  const [knockKnockMessage, setKnockKnockMessage] = useState("");
  let loggedUserId = "";
  let { client, designer } = purchase;

  if (role === 1) {
    loggedUserId = clientChatId;
  } else {
    loggedUserId = professionalChatId;
  }

  const dataChat = {
    currentUserId: loggedUserId,
    currentChatId: roomId,
  };

  // Send a message when the user press the Enter key
  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      sendMessage();
    }
  };

  // Get all the data from the chat
  const getChat = async (id) => {
    setChatMessages([]);

    const chatMessages = await axios.get(`/v1/chat-message/?chat=${id}`);

    setChatMessages(chatMessages.data.body.statusMessage);
  };

  // Functions to send a message
  const handleChange = (e) => {
    setMessageToSend(e.target.value);
  };

  const sendMessage = async () => {
    if (messageToSend) {
      const fullMessage = {
        chat: dataChat.currentChatId,
        user: dataChat.currentUserId,
        message: messageToSend,
      };

      await axios.post("/v1/chat-message", fullMessage);

      setMessageToSend("");
    }
  };

  // Send a Knock Knock notification with an interval of 20 minutes to send another
  const sendKnockKnock = async () => {
    if (role === 1) {
      await axios.post(
        `v1/virtual-consultancy/${purchase.hashid}/client/knock-knock-whatsapp`
      );
      setIsKnockKnockBlocked(true);

      successToast(
        `Has enviado un Whatsapp para avisarle a tu profesional que quieres reunirte con él/élla`
      );

      //TODO: Look for another way to keep the icon blocked without setTimeOut
      // taking into account setTimeOut does not work when Browser is refreshed.
      setTimeout(() => {
        setIsKnockKnockBlocked(false);
      }, 1200000);
    } else if (role === 2) {
      await axios.post(
        `v1/virtual-consultancy/${purchase.hashid}/professional/knock-knock-whatsapp`
      );
      setIsKnockKnockBlocked(true);

      successToast(
        `Has enviado un Whatsapp para avisarle a tu cliente que quieres reunirte con él/élla`
      );

      //TODO: Look for another way to keep the icon blocked without setTimeOut
      // taking into account setTimeOut does not work when Browser is refreshed.
      setTimeout(() => {
        setIsKnockKnockBlocked(false);
      }, 1200000);
    }
  };

  useEffect(() => {
    if (role === 1) {
      setKnockKnockMessage(
        "Haz clic en el puño para enviarle una notificación a tu profesional"
      );
    } else {
      setKnockKnockMessage(
        "Haz clic en el puño para enviarle una notificación a tu cliente"
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Get Chats
    const id = dataChat.currentChatId;
    getChat(id);

    // Listen for new messages with Socket
    socket.on(`${id}`, (data) => {
      setChatMessages((prevArray) => [...prevArray, data]);
    });
  }, [dataChat.currentChatId]);

  useEffect(() => {
    socket.on("receive_message", (data) => {});
    // eslint-disable-next-line
  }, [socket]);

  return (
    <Container showChat={showChat}>
      <Section>
        <div className="chat-header">
          <BtnClose onClick={handleShowChat}>
            <CloseIcon />
          </BtnClose>
          <UserImg>
            {role === 1 ? (
              <img src={designer.avatar} alt="imagenCliente" />
            ) : (
              <AccountCircleIcon
                style={{ width: "100%", height: "100%", color: "white" }}
              />
            )}
          </UserImg>
          {role === 1 ? (
            <p>
              {designer.first_name} {designer.last_name}
            </p>
          ) : (
            <p>
              {client.first_name} {client.last_name}
            </p>
          )}
          <TitleNock>
            <span>
              Nock
              <br /> Nock
            </span>
          </TitleNock>
          {isKnockKnockBlocked ? (
            <BtnHand>
              <FontAwesomeIcon
                className="blocked_icon_tok"
                icon="fa-solid fa-hand-back-fist"
                title="Espera 20 minutos para enviar otra notificación"
                style={{
                  fontSize: "1.5rem",
                }}
              />
            </BtnHand>
          ) : !purchase.finished_at ? (
            <BtnHand>
              <FontAwesomeIcon
                className="icon_tok"
                icon="fa-solid fa-hand-back-fist"
                title={knockKnockMessage}
                style={{
                  fontSize: "1.5rem",
                }}
                onClick={sendKnockKnock}
              />
            </BtnHand>
          ) : null}
        </div>
        <div className="chat-body">
          <ScrollToBottom className="message-container">
            <ChatMessageList chatMessages={chatMessages} dataChat={dataChat} />
            <div className="message-mega"></div>
          </ScrollToBottom>
        </div>
        <div className="chat-footer">
          {!purchase.finished_at ? (
            <>
              <input
                type="text"
                placeholder="Escribe tu mensaje"
                value={messageToSend}
                onChange={handleChange}
                onKeyDown={(e) => handleEnter(e)}
              />

              <SendIcon
                style={{
                  color: "#f5d975",
                  margin: " 0 1rem",
                  cursor: "pointer",
                }}
                onClick={sendMessage}
              />
            </>
          ) : null}
        </div>
      </Section>
    </Container>
  );
}

export default Chat;

const TitleNock = styled.div`
  display: inline-flex;
  color: #fff;
  position: absolute;
  top: 0.4rem;
  right: 3rem;
  font-size: 0.7rem;
`;
const Container = styled.div`
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-height: 90vh;
  max-height: 90vh;

  @media screen and (min-width: 961px) {
    display: flex;
  }

  @media screen and (max-width: 960px) {
    max-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    transition: all 0.3s;
    visibility: ${(props) => (props.showChat ? "visible" : "hidden")};
    opacity: ${(props) => (props.showChat ? "1" : "0")};
  }
`;

const Section = styled.div`
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-direction: column;
  width: 100%;
  position: relative;

  @media screen and (max-width: 960px) {
    height: 100%;
    width: 100%;
  }
`;

const BtnClose = styled.div`
  display: inline-flex;
  color: #fff;
  position: absolute;
  top: 1rem;
  left: 1rem;
  cursor: pointer;

  @media screen and (min-width: 961px) {
    display: none;
  }
`;

const BtnHand = styled.div`
  display: inline-flex;
  color: #fdd440;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  cursor: pointer;
`;
const UserImg = styled.div`
  position: relative;
  display: flex;
  width: 3rem;
  height: 3rem;

  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    max-width: 60px;
    min-width: 60px;
  }

  @media screen and (max-width: 479px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    max-width: 40px;
    min-width: 40px;
  }
`;
