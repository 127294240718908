import { useQuery } from "react-query";
import {
  getSalesConsultancies,
  getSalesConsultancyByHashid,
} from "../../../supplierEcommerce/services/api";
import { QUERY_KEYS } from "../queryKeys";

export const useGetSalesConsultancies = (isFinished: boolean = false) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SALES_CONSULTANCIES, isFinished],
    queryFn: () => getSalesConsultancies(isFinished),
    refetchOnWindowFocus: true,
  });
};

export const useGetSalesConsultancyByHashid = (hashid: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_SALES_CONSULTANCIES_BY_HASHID, hashid],
    queryFn: () => getSalesConsultancyByHashid(hashid),
    refetchOnWindowFocus: false,
    enabled: !!hashid,
  });
};
