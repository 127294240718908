import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ChevronRight, CircleDot, Clock, EyeOff } from "lucide-react";
import React, { MouseEvent, useEffect, useState } from "react";
import { useAuth, roles, getRoleName } from "../../../../services/auth";
import { useUserStore } from "../../../../Store/User";
import {
  useGetUserProfile,
  useUpdateUserProfileStatus,
} from "../../../../utils/react-query/queryEcommerce/queries";
import styles from "./ProfileMenu.module.scss";
import StatusOptionsMenu from "./StatusOptionsMenu";

const statusMap: Record<
  OnlineStatus,
  { label: string; color: string; icon: React.ReactNode }
> = {
  online: {
    label: "Activo",
    color: "online",
    icon: <CircleDot size={18} />,
  },
  away: {
    label: "Ocupado",
    color: "away",
    icon: <Clock size={18} />,
  },
  offline: {
    label: "Desconectado",
    color: "offline",
    icon: <EyeOff size={18} />,
  },
};

const ProfileMenu: React.FC = () => {
  const { startLogout } = useUserStore();
  const { currentRoleId } = useAuth();
  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useGetUserProfile();
  const { mutateAsync: updateProfileStatus } = useUpdateUserProfileStatus();
  const [currentStatus, setCurrentStatus] = useState(statusMap["offline"]);
  const roleName = getRoleName(currentRoleId);

  useEffect(() => {
    if (userProfile?.salesperson !== null) {
      setCurrentStatus(
        // @ts-ignore
        statusMap[userProfile?.salesperson?.online_status || "offline"]
      );
    }
  }, [userProfile]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleStatusChange = async (status: string) => {
    if (!status) return;
    const updatedStatus = await updateProfileStatus(status);
    if (updatedStatus) setSubMenuAnchorEl(null);
  };

  const handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuClick = (event: MouseEvent<HTMLElement>) => {
    setSubMenuAnchorEl(event.currentTarget);
  };

  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };

  if (isLoadingUserProfile) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {userProfile && (
        <div>
          <IconButton color="inherit" onClick={handleMenuClick}>
            {userProfile?.files.length > 0 ? (
              <Avatar alt="Remy Sharp" src={userProfile?.files[0]?.avatar} />
            ) : (
              <Avatar alt="imagen de perfil">
                {userProfile?.first_name ? userProfile?.first_name[0] : `P`}
              </Avatar>
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className={styles.profileInfo}>
              {userProfile?.files.length > 0 ? (
                <Avatar alt="Remy Sharp" src={userProfile?.files[0]?.avatar} />
              ) : (
                <Avatar alt="imagen de perfil">
                  {userProfile?.first_name ? userProfile?.first_name[0] : `P`}
                </Avatar>
              )}
              <div className={styles.profileInfoName}>
                {userProfile?.first_name && userProfile?.last_name && (
                  <h6>
                    {userProfile?.first_name} {userProfile?.last_name}
                  </h6>
                )}
                <p>{userProfile?.email}</p>
              </div>
            </div>
            <Divider />
            {currentRoleId === roles.salesperson &&
              userProfile.salesPerson !== null && (
                <>
                  <MenuItem onClick={handleSubMenuClick}>
                    <button className={styles.buttonStatus}>
                      <div className={styles.buttonStatusText}>
                        <span className={styles[currentStatus.color]}>
                          {currentStatus.icon}
                        </span>
                        <span>{currentStatus.label}</span>
                      </div>
                      <ChevronRight size={18} />
                    </button>
                  </MenuItem>
                  <Divider />
                </>
              )}
            <MenuItem>
              <ListItemIcon>
                <BuildIcon />
              </ListItemIcon>
              <a
                className={styles.linkConfiguration}
                href={`/${roleName}/configuracion`}
              >
                Configuración
              </a>
            </MenuItem>
            <Divider />
            <MenuItem onClick={startLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              Cerrar Sesión
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={subMenuAnchorEl}
            keepMounted
            open={Boolean(subMenuAnchorEl)}
            onClose={handleSubMenuClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <StatusOptionsMenu handleStatusChange={handleStatusChange} />
          </Menu>
        </div>
      )}
    </>
  );
};

export default ProfileMenu;
