import ChatMessage from "./ChatMessage";
import styles from "./EcommerceChat.module.scss";

type ChatMessageProps = {
  chatMessages: ChatMessages[];
  dataChat: any;
};

const ChatMessageList = ({ chatMessages, dataChat }: ChatMessageProps) => {
  return (
    <div className={styles.chatMessageList}>
      {chatMessages &&
        chatMessages.map((message) => {
          const isCurrentUser = message.user._id === dataChat.currentUserId;
          return (
            <ChatMessage isCurrentUser={isCurrentUser} message={message} />
          );
        })}
    </div>
  );
};
export default ChatMessageList;
