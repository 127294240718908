import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";

import EcommerceChat from "../../components/EcommerceChat/EcommerceChat";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import { useGetSalesConsultancyByHashid } from "../../utils/react-query/querySalesConsultancy/queries";
import EcommerceConsultancySummaryHeaderInfo from "./components/EcommerceConsultancySummaryHeaderInfo/EcommerceConsultancySummaryHeaderInfo";

import styles from "./EcommerceConsultancySummary.module.scss";

function EcommerceConsultancySummary() {
  const { setChatHashid } = useSnapshot(chatModalStore);
  const { hashid } = useParams<{ hashid: string }>();

  const { data: salesConsultancy, isLoading: isLoadingSalesConsultancy } =
    useGetSalesConsultancyByHashid(hashid);

  useEffect(() => {
    setChatHashid(hashid);
  }, [setChatHashid, hashid]);

  if (isLoadingSalesConsultancy) return <div>Loading...</div>;

  const client = salesConsultancy?.client || null;
  const product = salesConsultancy?.product || null;

  return (
    <section className="layout-dashboard-container">
      <EcommerceConsultancySummaryHeaderInfo
        client={client}
        product={product}
      />
      <div className={styles.ecommerceConsultancyContainer}>
        <section className={styles.ecommerceConsultancyContent}>
          <EcommerceChat />
          {/*Todo: in the future this section it is going to appear*/}
          {/*<ChatUserDetails />*/}
        </section>
      </div>
    </section>
  );
}

export default EcommerceConsultancySummary;
