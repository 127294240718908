import Avatar from "@material-ui/core/Avatar";

import "./Chat.css";
import SendIcon from "@material-ui/icons/Send";
import axios from "axios";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import ScrollToBottom from "react-scroll-to-bottom";

import { io } from "socket.io-client";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import chatModalStore from "../../ecommerce/store/ChatModalStore";
import { useAuth } from "../../services/auth";

import { BASE_API_URL } from "../../services/baseURL";
import ChatEmptyState from "./ChatEmptyState";
import ChatMessageList from "./ChatMessageList";

import styles from "./EcommerceChat.module.scss";
import { Link } from "react-router-dom";

const socket = io(BASE_API_URL);

function EcommerceChat() {
  const { currentRoleId } = useAuth();
  const { chatHashid } = useSnapshot(chatModalStore);
  const [chatMessages, setChatMessages] = useState<ChatMessages[]>([]);
  const [messageToSend, setMessageToSend] = useState("");
  const [roomUuid, setRoomUuid] = useState("");
  const [loggedUserUuid, setLoggedUserUuid] = useState("");
  const [salespersonInfo, setSalespersonInfo] =
    useState<SalesPersonInfo | null>(null);
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);

  const personName =
    currentRoleId !== 1
      ? `${clientInfo?.first_name || "Nombre"} ${
          clientInfo?.last_name || "Apellido"
        }`
      : `${salespersonInfo?.first_name || "Nombre"} ${
          salespersonInfo?.last_name || "Apellido"
        }`;

  const clientAvatar = clientInfo?.first_name.charAt(0).toUpperCase();
  const salesAvatar = salespersonInfo?.avatar;

  useEffect(() => {
    const fetchChatDetails = async () => {
      try {
        const response = await axios.get<GetSalesConsultancyResponse>(
          `/v2/sales-consultancies/${chatHashid}`
        );
        const { salesChat, salesperson, client } = response.data;

        setRoomUuid(salesChat?.room_uuid);
        setSalespersonInfo(salesperson);
        setClientInfo(client);

        if (currentRoleId === 1) {
          setLoggedUserUuid(salesChat?.client_uuid);
        } else {
          setLoggedUserUuid(salesChat?.salesperson_uuid);
        }
      } catch (error) {
        console.error("Error fetching chat details:", error);
      }
    };

    fetchChatDetails();
  }, [chatHashid, currentRoleId]);

  useEffect(() => {
    if (!roomUuid) return;

    const fetchChatMessages = async () => {
      try {
        const chatResponse = await axios.get(
          `/v1/chat-message?chat=${roomUuid}`
        );
        setChatMessages(chatResponse.data.body.statusMessage || []);
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      }
    };

    fetchChatMessages();
  }, [roomUuid]);

  const dataChat = {
    currentUserId: loggedUserUuid,
    currentChatId: roomUuid,
  };

  // Send a message when the user press the Enter key
  const handleEnter = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await sendMessage();
    }
  };

  // Functions to send a message
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessageToSend(e.target.value);
  };

  const sendMessage = async () => {
    if (messageToSend) {
      const fullMessage = {
        chat: roomUuid,
        user: loggedUserUuid,
        message: messageToSend,
      };

      await axios.post("/v1/chat-message", fullMessage);

      setMessageToSend("");
    }
  };

  useEffect(() => {
    // Listen for new messages with Socket
    socket.on(`${roomUuid}`, (data: ChatMessages) => {
      setChatMessages((prevArray) => [...prevArray, data]);
    });
  }, [roomUuid]);

  useEffect(() => {
    socket.on("receive_message", (data: any) => {});
    // eslint-disable-next-line
  }, [socket]);

  return (
    <div className={styles.chatContainer}>
      <section>
        <div className={styles.chatHeader}>
          <UserImg>
            {currentRoleId !== 1 ? (
              <Avatar>{clientAvatar}</Avatar>
            ) : (
              <Avatar alt="Remy Sharp" src={salesAvatar} />
            )}
          </UserImg>
          {personName && <h3 className="subtitle-2">{personName}</h3>}
          <Link
            to={`/videochat/sales-consultancy/${chatHashid}/${currentRoleId}`}
          >
            <button className={styles.videoChatButton}>Video Chat</button>
          </Link>
        </div>
        <div className={styles.chatBody}>
          <div className={styles.chatBodyContent}>
            <div className={styles.chatBodyInfo}>
              <ScrollToBottom className={styles.messageContainer}>
                {chatMessages.length > 0 ? (
                  <ChatMessageList
                    chatMessages={chatMessages}
                    dataChat={dataChat}
                  />
                ) : (
                  <ChatEmptyState currentRoleId={currentRoleId} />
                )}

                <div className="message-mega"></div>
              </ScrollToBottom>
            </div>
          </div>
        </div>
        <div className={styles.chatFooter}>
          <div className={styles.chatFooterContent}>
            <input
              type="text"
              value={messageToSend}
              onChange={handleChange}
              onKeyDown={(e) => handleEnter(e)}
              placeholder="Escribe un mensaje..."
              className={styles.chatFooterInput}
            />
            <button className={styles.chatFooterSendButton} type="submit">
              <SendIcon />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EcommerceChat;

const UserImg = styled.div`
  position: relative;
  display: flex;
  width: 3rem;
  height: 3rem;

  border-radius: 50%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    max-width: 60px;
    min-width: 60px;
  }

  @media screen and (max-width: 479px) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    max-width: 40px;
    min-width: 40px;
  }
`;
