import styled from "styled-components";
import remodelingHeaderImage from "../../assets/images/purchase-summary-bg-supermin.jpeg";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import VideocamIcon from "@material-ui/icons/Videocam";
import VirtualConsultancyStatus from "./virtualConsultancyStatus";
import { Link } from "react-router-dom";
export default function VirtualConsultancyHeaderInfo({
  purchase,
  handleShowChat,
  role,
}) {
  let { client, designer, hashid } = purchase;
  return (
    <Container>
      <Section>
        <DataHeader>
          <h2>
            {role === 1
              ? `¡Hola, ${client.first_name} ${client.last_name}!`
              : `¡Hola, ${designer.first_name} ${designer.last_name}!`}
          </h2>
          <p>
            {role === 1
              ? `Aquí podrás encontrar el paso a paso del proceso de remodelación
            adelantado por el diseñador `
              : `Aquí podrás encontrar el paso a paso del proceso de remodelación para tu cliente `}

            <span>
              {role === 1
                ? `${designer.first_name} ${designer.last_name}`
                : `${client.first_name} ${client.last_name}`}
            </span>
          </p>
          <DataRef>
            <div>
              <span>Referencia:</span>
              <span>{hashid}</span>
            </div>
            <div>
              <span>Estado del proceso:</span>
              <span>{purchase.finished_at ? "Finalizado" : "Activo"}</span>
            </div>
          </DataRef>
          <IconsContainer>
            <BtnChat onClick={handleShowChat}>
              <ChatBubbleIcon style={{ fontSize: "1.2rem" }} />
              <span>Chat</span>
            </BtnChat>
            {!purchase.finished_at ? (
              <Link
                style={{ textDecoration: "none" }}
                to={`/videochat/virtual-consultancy/${hashid}/${role}`}
              >
                <BtnCall>
                  <VideocamIcon />
                  <span>Videollamada</span>
                </BtnCall>
              </Link>
            ) : (
              <BtnCall style={{ disabled: true, cursor: "not-allowed" }}>
                <VideocamIcon />
                <span>Videollamada</span>
              </BtnCall>
            )}
          </IconsContainer>
        </DataHeader>
      </Section>
      <VirtualConsultancyStatus
        hashid={hashid}
        role={role}
        purchase={purchase}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  flex: 1;

  @media screen and (min-width: 961px) {
    overflow-y: scroll;
    max-height: 90vh;
    padding-bottom: 10px;
  }
`;

const Section = styled.div`
  width: 100%;
  margin: 0 auto;
  background-image: ${() => `url(${remodelingHeaderImage})`};
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.8);
  background-position: center center;
`;

const DataHeader = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  & > p {
    font-size: 0.9rem;

    span {
      font-weight: bold;
    }
  }

  @media screen and (min-width: 769px) {
    & > p {
      font-size: 1.2rem;
    }
  }
`;

const DataRef = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  text-align: left;
  line-height: 1rem;

  & > div {
    display: flex;
    justify-content: space-between;

    span:nth-child(1) {
      font-weight: bold;
      font-size: 0.9rem;
    }
    span:nth-child(2) {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 481px) {
    & > div {
      justify-content: flex-start;
      gap: 1rem;
    }
  }

  @media screen and (min-width: 769px) {
    & > div {
      span:nth-child(1) {
        font-weight: bold;
        font-size: 1rem;
      }
      span:nth-child(2) {
        font-size: 0.9rem;
      }
    }
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4rem 0 2rem 0;
  gap: 1rem;

  @media screen and (min-width: 481px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

const BtnChat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 15rem;
  border-radius: 1rem;
  background: #fdd440;
  color: #263238;
  padding: 0 2rem;
  gap: 0.5rem;

  & > span {
    display: block;
  }
  :hover {
    filter: brightness(103%);
    cursor: pointer;
  }

  @media screen and (min-width: 961px) {
    display: none;
    visibility: hidden;
  }
`;

const BtnCall = styled.a`
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  height: 3rem;
  width: 15rem;
  border-radius: 1rem;
  background: #fdd440;
  color: #263238;
  padding: 0 2rem;
  gap: 0.5rem;

  :hover {
    filter: brightness(103%);
    cursor: pointer;
  }

  @media screen and (min-width: 481px) {
    padding: 0 2rem;
    gap: 0.5rem;

    & > span {
      display: block;
    }
  }
`;
