import Axios from "axios";
import { handleError } from "../../utils/utils";

export async function getFavoritesProducts() {
  try {
    const favoritesProducts = await Axios.get(`/v2/favorite`);

    if (!favoritesProducts)
      throw Error("No se encontraron productos favoritos");

    return favoritesProducts.data;
  } catch (error) {
    handleError(error);
  }
}

export async function addProductToFavorites(productId: number) {
  try {
    await Axios.post(`/v2/favorite/add/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function removeProductFromFavorites(productId: number) {
  try {
    await Axios.delete(`/v2/favorite/remove/${productId}`);
  } catch (error) {
    handleError(error);
  }
}

export async function getProductById(productId: number) {
  try {
    const product = await Axios.get(`/v2/products/${productId}`);
    return product.data;
  } catch (error) {
    handleError(error);
  }
}

export async function createSalesConsultancy(
  salespersonUuid: string,
  salespersonOnlineStatus: string,
  productId: number
) {
  try {
    const salesConsultancy = await Axios.post(`/v2/sales-consultancies`, {
      salespersonUuid,
      salespersonOnlineStatus,
      productId,
    });
    return salesConsultancy.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSalesConsultancies(isFinished: boolean) {
  try {
    const salesConsultancies = await Axios.get(
      `/v2/sales-consultancies/list/all?isFinished=${isFinished}`
    );
    return salesConsultancies.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSalesConsultancyByHashid(hashid: string) {
  try {
    const salesConsultancy = await Axios.get(
      `/v2/sales-consultancies/${hashid}`
    );
    return salesConsultancy.data;
  } catch (error) {
    handleError(error);
  }
}
