//help to query keys QueryCheckout and queryVirtualConsultancy and queryEcommerce

export enum QUERY_KEYS {
  // CHECKOUT KEYS
  GET_CITIES = "cities",
  GET_ALL_ADDRESSES = "addresses",
  GET_SHIPPING_COST = "shippingCost",
  GET_SHIPPING_COST_BY_COMPANY_NAME = "shippingCostByCompanyName",

  // VIRTUAL CONSULTANCY KEYS
  GET_DESIGNERS = "designers",

  // ECOMMERCE KEYS
  GET_CATEGORIES = "categories",
  GET_FAVORITES = "favorites",
  GET_PRODUCT_BY_ID = "productById",
  GET_COMPANY_DATA = "getCompanyData",
  GET_SALES_PERSON_BY_COMPANYNAME = "getSalesPerson",
  GET_ALL_PRODUCTS = "getAllProducts",
  GET_FILTERS = "getFilters",
  GET_SALES_PEOPLE_BY_ID = "getSalesPeopleById",

  // SUPPLIER KEYS
  GET_COLORS = "getColors",
  GET_ADDRESSES = "getAddresses",
  GET_ECOMMERCE_PREFERENCES = "getEcommercePreferences",
  GET_WOMPI_PUBLIC_KEY = "getWompiPublicKey",
  GET_WOMPI_PUBLIC_KEY_BY_SUPPLIER = "getWompiPublicKeyBySupplier",
  GET_ENVIA_PUBLIC_KEY = "getEnviaPublicKey",
  GET_SALES_PERSON = "getSalesPerson",
  GET_SALES_PERSON_BY_ID = "getSalesPersonById",

  // NAVBAR LINKS KEYS
  GET_NAVBAR_LINKS = "navbarLinks",

  //NAVBAR USER PERFILE
  GET_USER_PROFILE = "getUserProfile",

  // SOCIAL MEDIA LINKS KEYS
  GET_SOCIAL_MEDIA = "getSocialMedia",
  GET_SOCIAL_MEDIA_LINKS = "getSocialMediaLinks",

  // SALES CONSULTANCY
  GET_SALES_CONSULTANCIES = "getSalesConsultancies",
  GET_SALES_CONSULTANCIES_BY_HASHID = "getSalesConsultByHashid",
}
