import { proxy } from "valtio";

export interface ChatModalStoreProps {
  isOpen: boolean;
  selectedChatId: number | null;
  salesPersonUuid: string | null;
  chatHashid: string | null;
  setChatHashid: (chatHashid: string) => void;
  onOpen: () => void;
  onClose: () => void;
  reset: () => void;
}

const chatModalStore = proxy<ChatModalStoreProps>({
  isOpen: false,
  selectedChatId: null,
  salesPersonUuid: null,
  chatHashid: null,
  setChatHashid: (chatHashid: string) => {
    chatModalStore.chatHashid = chatHashid;
  },
  onOpen: () => {
    chatModalStore.isOpen = true;
  },

  onClose: () => {
    chatModalStore.isOpen = false;
    chatModalStore.reset();
  },

  reset: () => {
    chatModalStore.selectedChatId = null;
    chatModalStore.salesPersonUuid = null;
  },
});

export default chatModalStore;
