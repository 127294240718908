import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import styles from "./EcommerceConsultancySummaryHeaderInfo.module.scss";

type EcommerceConsultancySummaryHeaderInfoProps = {
  client: ClientInfo;
  product: ProductInfo;
};

export default function EcommerceConsultancySummaryHeaderInfo({
  client,
  product,
}: EcommerceConsultancySummaryHeaderInfoProps) {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.headerContainer}>
      <button className={styles.buttonGoBack} onClick={handleGoBack}>
        <ArrowBackIosIcon />
      </button>
      <img
        src={product.image}
        alt={`Imagen del producto ${product.name}`}
        className={styles.productImage}
      />
      <div className={styles.infoContainer}>
        <h2 className="subtitle-2">
          Cliente:{" "}
          <span className="body-1">
            {client.first_name} {client.last_name}
          </span>
        </h2>
        <h2 className="subtitle-2">
          Producto: <span className="body-1">{product.name}</span>
        </h2>
      </div>
    </div>
  );
}
