export const formatChatDate = (isoDate: string): string => {
  const date = new Date(isoDate);
  const now = new Date();

  const isToday = date.toDateString() === now.toDateString();
  const isYesterday =
    new Date(now.setDate(now.getDate() - 1)).toDateString() ===
    date.toDateString();

  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const time = new Intl.DateTimeFormat("en-US", options).format(date);

  if (isToday) {
    return `Hoy, ${time}`;
  }

  if (isYesterday) {
    return `Ayer, ${time}`;
  }

  return `${date.toLocaleDateString("es-ES")} ${time}`;
};
